//
//
//
//
//
//
//
//
//
//
//
//
//

import outboundMixin from '@/mixins/outbound'
export default {
  mixins: [outboundMixin],
  computed: {
    selectedOutboundFilters: {
      get () {
        return this.$store.state.outbound.outboundFilters
      },
      set (event) {
        this.$store.dispatch('outbound/setOutboundFilters', event)
      }
    },
    items () {
      return [
        {
          text: '---- User ----',
          disabled: true
        },
        {
          value: 'match::lastHandledBy.keyword::unassigned',
          text: 'Unassigned'
        },
        {
          value: 'match::lastHandledBy.keyword::' + this.$auth.email,
          text: this.$auth.email
        },
        {
          text: '---- Quoter ----',
          disabled: true
        },
        {
          value: 'wildcard::qrn.keyword::KA*',
          text: 'Auto'
        },
        {
          value: 'wildcard::qrn.keyword::KP*',
          text: 'Home'
        },
        {
          value: 'wildcard::qrn.keyword::KF*',
          text: 'Commercial Auto'
        },
        {
          value: 'wildcard::qrn.keyword::KB*',
          text: 'Commercial Property'
        },
        {
          value: 'wildcard::qrn.keyword::KI*',
          text: 'IRCA Quoter'
        },
        {
          text: '---- Province ----',
          disabled: true
        },
        {
          value: 'match::province::ON',
          text: 'Ontario'
        },
        {
          value: 'match::province::AB',
          text: 'Alberta'
        },
        {
          value: 'match::province::BC',
          text: 'British Columbia'
        },
        {
          text: '---- Quote/Lead Type ----',
          disabled: true
        },
        {
          value: 'match::leadType.keyword::qcp-outbound-scoop-scoop',
          text: 'Scoop.ca Unconv Scoop'
        },
        {
          value: 'match::leadType.keyword::qcp-outbound-bolscoop-scoop',
          text: 'Scoop.ca Unconv Scoop BO'
        },
        {
          value: 'match::leadType.keyword::converted-outbound-scoop-scoop',
          text: 'Scoop.ca Converted Scoop'
        },
        {
          value: 'match::leadType.keyword::converted-outbound-bolscoop-scoop',
          text: 'Scoop.ca Converted Scoop BO'
        },
        ...this.newLeadTypes,
        {
          text: '---- Attempts ----',
          disabled: true
        },
        {
          value: 'match::attempts::0',
          text: '0'
        },
        {
          value: 'match::attempts::1',
          text: '1'
        },
        {
          value: 'match::attempts::2',
          text: '2'
        },
        {
          value: 'match::attempts::3',
          text: '3'
        }
      ]
    }
  }
}
