import Vue from 'vue'

class PermissionsService {
  constructor ($auth) {
    this.$auth = $auth
  }

  get permissionScopes () {
    const quoteScopes = [
      'Quotes',
      'LRApi::getLeads',
      'LRApi::getLead',
      'LRApi::getLeadByQrn',
      'ResendEmail',
      'LRApi::resendEmail',
      'SingShowHidden'
    ]

    const transferScopes = [
      'Transfers',
      'Transfers::BrokerList',
      'Transfers::NewTransferFlow',
      'LRApi::getTransfers',
      'LRApi::getTransfersBySource',
      'LRApi::createTransfer',
      'LRApi::updateTransfer',
      'LRApi::uploadEzleadsTransfer',
      'LRApi::deleteTransfer'
    ]

    const creditCardScopes = [
      'CreditCard',
      'LRApi::createBMOData',
      'LRApi::createFintelData',
      'LRApi::getPromoApplications',
      'LRApi::getPromoDisputeApplications',
      'LRApi::getPromoFilters',
      'LRApi::downloadCSVFile',
      'LRApi::processGiftCards',
      'LRApi::confirmGiftCardApplications',
      'LRApi::getPromoDisputeApplications',
      'LRApi::updateDisputeStatus',
      'LRApi::getGiftCardApplications',
      'LRApi::downloadUnprocessedGiftCards',
      'LRApi::getTransferSessionAttributes',
      'LRApi::createTransferSessionAttributes'
    ]

    const callLoggerScopes = [
      'CallLogger',
      'CallLogsDialog',
      'CallLogger::searchCallLogs',
      'CallLogger::createCallLog',
      'CallLogger::updateCallLog',
      'CallLogger::cancelOutboundCallLog',
      'CallLogger::deleteCallLog'
    ]

    const outboundScopes = [
      'Outbound',
      'Outbound::getBubblePrioritySort',
      'Outbound::searchOutbound',
      'Outbound::searchOutboundByKeyValue',
      'Outbound::updateOutbound',
      'Outbound::reinsertLeadToOutbound',
      'Outbound::addLatestCallLogToOutbound',
      'Outbound::removeLeadFromOutbound',
      'Outbound::closeOutboundLeads',
      'Outbound::searchRealtimeOutboundLeads',
      'Outbound::changeOutboundLeadQrn',
      'Outbound::createOutboundResolverLog',
      'QuotePageOutboundControls'
    ]

    const excludeOutboundScopes = [
      'ExcludeOutbound',
      'Outbound::searchExcludeOutbound',
      'Outbound::createExcludeOutbound',
      'Outbound::updateExcludeOutbound',
      'Outbound::deleteExcludeOutbound'
    ]

    const adminSettingScopes = [
      'Settings',
      'EpicApi::searchBrokerList',
      'EpicApi::reIndexBrokerList',
      'Outbound::searchSuppliers'
    ]

    const awsConnectScopes = [
      'AWSConnect::updateContactAttributes',
      'AWSConnect::getAgentsOnline'
    ]

    const phonedialerScopes = [
      'PhoneDialer',
      'BrokerPhoneDialer',
      'LRApi::getAgentDetails',
      'LRApi::getActivePhoneCall',
      'LRApi::startPhoneCall',
      'LRApi::endPhoneCall'
    ]

    return [
      'Announcements',
      'Dashboard',
      'Domains',
      'Users',
      'Users::groups',
      'Groups',
      'Permissions',
      'Profile',
      'ControlCentre',
      'LRApi::createJsonLog',
      'LRApi::completeQualtricsQuoteEvent',
      'Tools',
      'LRApi::createUnfulfilledLead',
      ...phonedialerScopes,
      ...quoteScopes,
      ...transferScopes,
      ...creditCardScopes,
      ...callLoggerScopes,
      ...outboundScopes,
      ...excludeOutboundScopes,
      ...awsConnectScopes,
      ...adminSettingScopes
    ]
  }

  // QuickLinks
  groupCanViewQuickLinks () {
    return this.$auth.accessChecks('QuickLinks', ['VIEW'])
  }

  // Quotes
  groupCanViewQuotes () {
    return this.$auth.accessChecks('Quotes', ['VIEW'])
  }

  groupCanReadQuotes () {
    return this.$auth.accessChecks('Quotes', ['READ'])
  }

  groupCanModifyQuotes () {
    return this.$auth.accessChecks('Quotes', ['UPDATE'])
  }

  groupCanResendEmail () {
    return this.$auth.accessChecks('ResendEmail', ['VIEW']) && this.$auth.accessChecks('LRApi::resendEmail', ['UPDATE'])
  }

  groupCanViewSingHiddenFields () {
    return this.$auth.accessChecks('SingShowHidden', ['VIEW'])
  }

  // Control Centre
  groupCanViewControlCentre () {
    return this.$auth.accessChecks('ControlCentre', ['VIEW'])
  }

  groupCanReadControlCentreConfig () {
    return this.$auth.accessChecks('ControlCentre', ['READ'])
  }

  groupCanUpdateControlCentreConfig () {
    return this.$auth.accessChecks('ControlCentre', ['UPDATE'])
  }

  groupCanViewOutboundRealtime () {
    return this.$auth.accessChecks('ControlCentre', ['VIEW'])
  }

  // Call Logger
  groupCanViewCallLogger () {
    return this.$auth.accessChecks('CallLogger', ['VIEW'])
  }

  groupCanViewCallLogsDialog () {
    return this.$auth.accessChecks('CallLogsDialog', ['VIEW'])
  }

  groupCanCreateCallLogs () {
    return this.$auth.accessChecks('CallLogger::createCallLog', ['CREATE'])
  }

  groupCanUpdateCallLogs () {
    return this.$auth.accessChecks('CallLogger::updateCallLog', ['UPDATE'])
  }

  groupCanReadCallLogs () {
    return this.$auth.accessChecks('CallLogger::searchCallLogs', ['READ'])
  }

  groupCanDeleteCallLogs () {
    return this.$auth.accessChecks('CallLogger::deleteCallLog', ['DELETE']) && this.$auth.groups.some(group => /Admin/.test(group))
  }

  // Transfers
  groupCanViewTransfers () {
    return this.$auth.accessChecks('Transfers', ['VIEW'])
  }

  groupCanReadTransfers () {
    return this.$auth.accessChecks('Transfers', ['READ'])
  }

  groupCanCreateTransfers () {
    return this.$auth.accessChecks('Transfers', ['CREATE'])
  }

  groupCanUpdateTransfers () {
    return this.$auth.accessChecks('Transfers', ['UPDATE'])
  }

  groupCanDeleteTransfers () {
    return this.$auth.accessChecks('Transfers', ['DELETE'])
  }

  groupCanViewTransfersBrokerList () {
    return this.$auth.accessChecks('Transfers::BrokerList', ['VIEW'])
  }

  groupCanUpdateTransfersType () {
    return this.$auth.accessChecks('LRApi::uploadEzleadsTransfer', ['UPDATE'])
  }

  groupCanViewTransferReassignment () {
    return this.$auth.accessChecks('TransferReassignment', ['VIEW']) && this.groupCanUpdateTransfersType() && this.$auth.accessChecks('EpicApi::searchBrokerList', ['READ'])
  }

  groupCanViewNewTransferFlow () {
    return this.$auth.accessChecks('Transfers::NewTransferFlow', ['VIEW'])
  }

  groupCanUpdateNewTransferFlow () {
    return this.$auth.accessChecks('Transfers::NewTransferFlow', ['UPDATE'])
  }

  // Phone Dialer
  groupCanViewPhoneDialer () {
    return this.$auth.accessChecks('PhoneDialer', ['VIEW'])
  }

  groupCanViewBrokerPhoneDialer () {
    return this.$auth.accessChecks('BrokerPhoneDialer', ['VIEW'])
  }

  // Outbound
  groupCanViewOutbound () {
    return this.$auth.accessChecks('Outbound', ['VIEW'])
  }

  groupCanSearchOutbound () {
    return this.$auth.accessChecks('Outbound::searchOutbound', ['READ'])
  }

  groupCanReadOutboundQuotes () {
    return this.$auth.accessChecks('Outbound', ['READ'])
  }

  groupCanViewQuotePageOutboundControls () {
    return this.$auth.accessChecks('QuotePageOutboundControls', ['VIEW'])
  }

  groupCanCloseOutboundLeads () {
    return this.$auth.accessChecks('Outbound::closeOutboundLeads', ['UPDATE'])
  }

  // Exclude Outbound
  groupCanViewExcludeOutbound () {
    return this.$auth.accessChecks('ExcludeOutbound', ['VIEW'])
  }

  groupCanReadExcludeOutbound () {
    return this.$auth.accessChecks('ExcludeOutbound', ['READ'])
  }

  groupCanCreateExcludeOutbound () {
    return this.$auth.accessChecks('ExcludeOutbound', ['CREATE'])
  }

  groupCanUpdateExcludeOutbound () {
    return this.$auth.accessChecks('ExcludeOutbound', ['UPDATE'])
  }

  groupCanDeleteExcludeOutbound () {
    return this.$auth.accessChecks('ExcludeOutbound', ['DELETE'])
  }

  // Admin Settings
  groupCanViewAdminSettings () {
    return this.$auth.accessChecks('Settings', ['VIEW'])
  }

  // Credit Card Admin
  groupCanUpload () {
    return this.$auth.accessChecks('LRApi::createBMOData', ['CREATE']) || this.$auth.accessChecks('LRApi::createFintelData', ['CREATE'])
  }

  groupCanViewPromoApplications () {
    return this.$auth.accessChecks('LRApi::getPromoApplications', ['CREATE'])
  }
}

export default (context, inject) => {
  const permissionsService = new PermissionsService(context.$auth)
  context.$permissions = Vue.prototype.$permissions = Vue.$permissions = permissionsService
  inject('permissions', permissionsService)
}
